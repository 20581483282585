<template>
	<div class="mimdgFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mimdg.clase')" :value="getClaseValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mimdg.variacion')" :value="getVariacionValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mimdg.version')" :value="getVersionValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mimdg-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mimdg'
		};
	},
	computed: {
		getClaseValue() {
			return this.model && this.model.clase ? this.model.clase : '-';
		},
		getVariacionValue() {
			return this.model && this.model.variacion ? this.model.variacion : '-';
		},
		getVersionValue() {
			return this.model && this.model.version ? this.model.version : '-';
		},
	}
}
</script>
